<template>
  <a :href="`/${props.article.full_slug}`" article-card v-if="article" :data-type="article.component">
    <figure v-if="article.component === 'page'" class="article-card-image">
      <img v-if="article.seo.og_image" :src="optimizeImage(article.seo.og_image)" :alt="`Article Image ${article.seo.title}`" loading="lazy" class="article-card-image-element" />
    </figure>
    <figure v-else-if="article.component === 'search'" class="article-card-image">
      <img v-if="article.seo.og_image" :src="optimizeImage(article.seo.og_image)" :alt="`Article Image ${article.seo.title}`" loading="lazy" class="article-card-image-element" />
    </figure>
    <figure v-else class="article-card-image" :class="{ 'article-card-has-mobile': article.image_mobile?.filename }">
      <NuxtImg provider="storyblok" v-if="article.image?.filename" :src="article.image.filename" :alt="`Article Image ${article.image.alt}`" loading="lazy" class="article-card-image-element" :width="dimensions.width" :height="dimensions.height" />
      <NuxtImg provider="storyblok" v-if="article.image_mobile?.filename" :src="article.image_mobile.filename" :alt="`Article Image Mobile ${article.image_mobile.alt}`" loading="lazy" class="article-card-image-element-mobile" :width="150" :height="150" />
    </figure>
    <div class="article-card-details">
      <div class="article-card-meta">
        <div class="article-card-tags" v-if="article?.tags?.value?.length > 0">
          <div v-for="(tag, t) in article?.tags?.value" :key="t" v-text="tag" @click="onTagClick($event, tag)"></div>
        </div>
        <div v-else class="article-card-tags"><div>Article</div></div>
        <div class="article-card-duration" v-text="`${article?.duration || 3} min read`"></div>
      </div>
      <div class="article-card-title" v-text="article.seo.title"></div>
      <div class="article-card-description" v-text="stringTruncate(article.seo.description, 160)"></div>
    </div>
  </a>
</template>
<style src="~/assets/scss/section/article-card.scss" lang="scss"></style>
<script setup>
  const props = defineProps({ article: Object });
  const article = props.article.content;

  const onTagClick = (event, tag) => {
    event.preventDefault();
    const tagsUrl = props.article.full_slug.split("/");
    window.location.assign(`/resources/${tagsUrl[1]}/tag/${tag}`);
  };

  const dimensions = computed(() => {
    if (article.image) {
      const urls = article.image?.filename?.split("/")[5].split("x");
      return {
        width: urls[0],
        height: urls[1],
      };
    }
    return { width: 0, height: 0 };
  });

</script>
